/***** Category *******/
/*.red{
  background-color:#A82282;
}
.blue{
  background-color:#2230A8;
}
.green{
  background-color:#22A86A;
}
.yellow{
  background-color:#FFFF00;
}
*/
//Socity colors

//Colors
.blue{
    background-color: rgba(34,	48,	168, 1);
}
.red{
    background-color: rgba(183, 28, 44, 1);
}
.green{
    background-color: rgba(49, 177, 150, 1);
}
.grey{
    background-color: rgba(36, 51, 36, 1);
}
.purple{
    background-color: rgba(123, 31, 102, 1);
}

.circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.category-map-icon{
  background-color: #8F8F8F;
  width: 24px;
  height: 24px;
}
.dropdown.show .dropdown-toggle .category-map-icon, .dropdown-toggle:hover .category-map-icon{
  background-color: #FFFFFF;
}
.categories_place{
  mask-image: url("/assets/img/categories/categories_place.svg");
}
.categories_event{
  mask-image: url("/assets/img/categories/categories_event.svg");
}
.categories_accomodation{
  mask-image: url("/assets/img/categories/categories_accomodation.svg");
}
.categories_service{
  mask-image: url("/assets/img/categories/categories_place.svg");
}

//SoCITY map icons
/** Going out **/
.categories_going_out{
  mask-image: url("/assets/img/categories/categories_going_out.svg");
}
.categories_bars{
  mask-image: url("/assets/img/categories/categories_bars.svg");
}
.categories_maquis{
  mask-image: url("/assets/img/categories/categories_maquis.svg");
}
.categories_cafes{
  mask-image: url("/assets/img/categories/categories_cafes.svg");
}
.categories_caves{
  mask-image: url("/assets/img/categories/categories_caves.svg");
}
.categories_clubs{
  mask-image: url("/assets/img/categories/categories_clubs.svg");
}
.categories_restaurants{
  mask-image: url("/assets/img/categories/categories_restaurants.svg");
}

/** Events **/
.categories_event{
  mask-image: url("/assets/img/categories/categories_event.svg");
}
.categories_sponsoring{
  mask-image: url("/assets/img/categories/categories_sponsoring.svg");
}
.categories_birthdays{
  mask-image: url("/assets/img/categories/categories_birthdays.svg");
}
.categories_concert{
  mask-image: url("/assets/img/categories/categories_concert.svg");
}
.categories_sport{
  mask-image: url("/assets/img/categories/categories_sport.svg");
}
.categories_tasting{
  mask-image: url("/assets/img/categories/categories_tasting.svg");
}
.categories_fitness{
  mask-image: url("/assets/img/categories/categories_fitness.svg");
}
.categories_foot{
  mask-image: url("/assets/img/categories/categories_foot.svg");
}
.categories_gastro{
  mask-image: url("/assets/img/categories/categories_gastro.svg");
}
.categories_karaoke{
  mask-image: url("/assets/img/categories/categories_karaoke.svg");
}
.categories_launches{
  mask-image: url("/assets/img/categories/categories_launches.svg");
}
.categories_dancing_nights{
  mask-image: url("/assets/img/categories/categories_dancing_nights.svg");
}
.categories_private_party{
  mask-image: url("/assets/img/categories/categories_private_party.svg");
}
.categories_vip{
  mask-image: url("/assets/img/categories/categories_vip.svg");
}

/** where to stay **/
.categories_where_to_stay{
  mask-image: url("/assets/img/categories/categories_where_to_stay.svg");
}
.categories_hotels{
  mask-image: url("/assets/img/categories/categories_hotels.svg");
}
.categories_houses{
  mask-image: url("/assets/img/categories/categories_houses.svg");
}
.categories_lounges{
  mask-image: url("/assets/img/categories/categories_lounges.svg");
}
.categories_rooms{
  mask-image: url("/assets/img/categories/categories_rooms.svg");
}

/** distributeurs **/
.categories_distributers{
  mask-image: url("/assets/img/categories/categories_distributers.svg");
}
.categories_petrol_stations{
  mask-image: url("/assets/img/categories/categories_petrol_stations.svg");
}
.categories_supermarkets{
  mask-image: url("/assets/img/categories/categories_supermarkets.svg");
}
.categories_where_to_sleep{
  mask-image: url("/assets/img/categories/categories_where_to_sleep.svg");
}

/** Category selectbox **/
.category-select{
  overflow-y: scroll;
  height: 200px;
  width: 200px;
}
